import { makeAutoObservable, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { createBot } from "src/api/bots/DEX_NE/create";
import { getParties } from "src/api/userManager/partiesAPI";
import { BotNavigateCallback } from "src/components/BotCreating/DEX_NE/hooks";
import {
  PrimitiveSelectorValue,
  RawPrimitiveSelectorValue,
  getSelectorList,
  getSelectorValueFromOptions,
  selectorValueToPrimitiveValue,
  toPrimitiveSelectorValue,
} from "src/helpers/forms/selectors";
import { RequiredFormData } from "src/helpers/forms/types";
import { makeLoggable } from "src/helpers/logger";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { DEXCommonExchangeVersion } from "src/modules/bots";
import { DEX_VERSION_OPTIONS } from "src/state/DEXV2/DEXV2Create/Create/constants";
import { IStrategies, StrategiesStore } from "src/state/shared/DEX/StrategiesStore";
import { IDEXNEChainsInfoProvider } from "../Info";
import {
  ILimitStrategiesBinder,
  LimitStrategiesBinderStore,
} from "../shared/LimitStrategiesBinder";
import { DEXNECreateSettings, DEXNELimitStrategy } from "../shared/types";
import { INITIAL_DEXNE_CREATE_SETTINGS } from "./constants";
import { mapChainExchangesToChainVersionExchanges, settingsToRequestData } from "./mappers";
import { LimitStrategiesProvider } from "./providers/LimitStrategiesProvider";
import { FormSelectorValue, FormSelectors, GetSelectorProps } from "./types";

interface IDEXNECreateParams {
  chainsInfoProvider: IDEXNEChainsInfoProvider;
}

export class DEXNECreateStore implements IDisposable {
  private _data: DEXNECreateSettings = INITIAL_DEXNE_CREATE_SETTINGS;

  private _loading = false;

  private _parties: string[] = [];

  private _limitStrategiesState: IStrategies<DEXNELimitStrategy>;

  private _limitStrategiesBinder: ILimitStrategiesBinder & IDisposable;

  private _currentNetwork: string = "";

  private _currentExchange: string = "";

  private _currentDexVersion: DEXCommonExchangeVersion | "" = "";

  private _chainsInfoProvider: IDEXNEChainsInfoProvider;

  constructor({ chainsInfoProvider }: IDEXNECreateParams) {
    makeAutoObservable<any, "_getSelectorOptions">(this, { _getSelectorOptions: false });

    this._limitStrategiesBinder = new LimitStrategiesBinderStore();

    const limitStrategiesProvider = new LimitStrategiesProvider(this._limitStrategiesBinder);

    this._limitStrategiesState = new StrategiesStore(limitStrategiesProvider);

    this._chainsInfoProvider = chainsInfoProvider;

    makeLoggable<any>(this, {
      data: true,
      _currentNetwork: true,
      _currentExchange: true,
    });
  }

  get data() {
    return toJS(this._data);
  }

  get loading() {
    return this._loading;
  }

  private get _dexVersionExchanges() {
    const { chainExchanges } = this._chainsInfoProvider;

    const chainVersionExchanges = mapChainExchangesToChainVersionExchanges(chainExchanges);

    return chainVersionExchanges;
  }

  private get _chainVersionExchangesInfo() {
    const network = this._currentNetwork;

    const version = this._currentDexVersion;

    if (!network || !version) return [];

    const versionExchanges = this._dexVersionExchanges[network];
    if (!versionExchanges) return [];

    const exchanges = versionExchanges[version];
    if (!exchanges) return [];

    return exchanges;
  }

  private get _chainVersionExchanges() {
    const exchanges = this._chainVersionExchangesInfo;

    return exchanges.map(({ name }) => name);
  }

  private get _currentExchangeInfo() {
    const exchange = this._currentExchange;

    const exchanges = this._chainVersionExchangesInfo;

    return exchanges.find(({ name }) => name === exchange);
  }

  private get _chainsInfo() {
    return filterBoolean(Object.values(this._chainsInfoProvider.chains));
  }

  private get _chainNames() {
    return this._chainsInfo.map(({ name }) => name);
  }

  private get _chainsOptions() {
    return getSelectorList(this._chainNames);
  }

  private get _versionsOptions() {
    return DEX_VERSION_OPTIONS;
  }

  private get _chainMetaMap() {
    return this._chainsInfoProvider.chainMetaMap;
  }

  get chainMeta() {
    const currentNetwork = this._currentNetwork;
    if (!currentNetwork) return undefined;

    return this._chainMetaMap[currentNetwork];
  }

  setCurrentNetwork = (network: string) => {
    this._currentNetwork = network;
  };

  setCurrentDexVersion = (version: DEXCommonExchangeVersion | "") => {
    this._currentDexVersion = version;
  };

  setCurrentExchange = (exchange: string) => {
    this._currentExchange = exchange;
  };

  bindLimitStrategies: ILimitStrategiesBinder["bindStrategies"] = (...args) =>
    this._limitStrategiesBinder.bindStrategies(...args);

  get limitStrategies() {
    return this._limitStrategiesBinder.strategies;
  }

  get limitStrategiesState() {
    return this._limitStrategiesState;
  }

  private _setPartiesList = (parties: string[]) => {
    this._parties = parties;
  };

  private _getPartiesList = async () => {
    try {
      const { data, isError } = await getParties();

      if (!isError) {
        this._setPartiesList(data);
      } else {
        this._setPartiesList([]);
      }
    } catch (err) {
      this._setPartiesList([]);
      logError(err);
    }
  };

  getInitialData = async () => {
    await Promise.all([this._getPartiesList()]);
  };

  private _setLoading = (bool: boolean) => {
    this._loading = bool;
  };

  private _selectorEnabled = computedFn((key: FormSelectors) => {
    switch (key) {
      case "base_data.version": {
        return Boolean(this._currentNetwork);
      }
      case "base_data.exchange": {
        return Boolean(this._currentDexVersion);
      }
      default: {
        return true;
      }
    }
  });

  private _getSelectorValue = (key: FormSelectors) => (value: FormSelectorValue<FormSelectors>) => {
    switch (key) {
      case "base_data.version": {
        const options = this._getSelectorOptions(
          key
        ) as PrimitiveSelectorValue<RawPrimitiveSelectorValue>[];

        const rawValue = getSelectorValueFromOptions(options)(value) ?? null;
        return rawValue;
      }
      default: {
        return toPrimitiveSelectorValue(value);
      }
    }
  };

  private _getSelectorOptions = computedFn((key: FormSelectors) => {
    switch (key) {
      case "base_data.version": {
        return this._versionsOptions;
      }
      case "base_data.network": {
        return this._chainsOptions;
      }

      case "base_data.party": {
        return getSelectorList(this._parties);
      }
      case "base_data.exchange": {
        return getSelectorList(this._chainVersionExchanges);
      }
    }
  });

  setData = (data: Partial<DEXNECreateSettings>) => {
    this._data = { ...this._data, ...data };
  };

  getSelectorProps = <K extends FormSelectors>(key: K): GetSelectorProps<K> => ({
    options: this._getSelectorOptions(key) as PrimitiveSelectorValue<FormSelectorValue<K>>[],
    onChangeMapper: selectorValueToPrimitiveValue,
    valueMapper: this._getSelectorValue(key) as (
      value: FormSelectorValue<K>
    ) => PrimitiveSelectorValue<FormSelectorValue<K>> | null,
    isDisabled: !this._selectorEnabled(key),
  });

  submitAll = async (data: DEXNECreateSettings, navigate?: BotNavigateCallback) => {
    this._setLoading(true);

    try {
      const requestData = settingsToRequestData(data as RequiredFormData<DEXNECreateSettings>);

      const { isError, data: response } = await createBot(requestData);

      if (!isError) {
        showSuccessMsg("Profile created successfully");
        navigate?.(response.bot_id, data.base_data.party);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._limitStrategiesBinder.destroy();
  };
}
