import { trimStartEndSlashes } from "src/helpers/url";

export const API_V1_PREFIX = "api/v1";

export enum ApiModules {
  WatchList = "watchlist",
  Auth = "auth",
  Bot = "bot",
  Binding = "binding",
  Funding = "funding",
  Note = "note",
  Chart = "chart",
  User = "user",
  Exchange = "exchange",
  Login = "login",
  Parties = "parties",
  Party = "party",
  Accounts = "accounts",
  Account = "account",
  SwapBot = "swap-bot",
  SwapBots = "swap-bots",
  Price = "price",
  Token = "token",
  Terminal = "terminal",
  Grid = "grid",
  CandleAnalytics = "candle-analytics",
  DEXV2Bot = "dex/bot",
  DEXV2Bots = "dex/bots",
  DEXNE = "nedex",
  DEXNEBots = "nedex/bots",
  DEXNEBot = "nedex/bot",
  Empty = "",
  MultiGrinder = "multigrinder",
  Tickers = "tickers",
  Dashboard = "dashboard",
  CEXLauncher = "cexlauncher",
}

export const apiUrl = (module: ApiModules, url: string, isQueryUrl: boolean = false) => {
  const delimiter = isQueryUrl ? "" : "/";
  return `${module}${delimiter}${trimStartEndSlashes(url)}`;
};

export const joinQueryValues = (values: string[]) => values.join(",");
